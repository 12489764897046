<template>
  <div class="orderDetails">
    <div class="trade_tips">{{ $t('app.trade_tips') }}</div>
    <div class="ticketDiv">
      <div class="ticket1">
        <img :src="ticketDetail?.productPicturePath ? ticketDetail.productPicturePath : NoPicture" />
        <div class="ticket_header">
          <div class="title1">
            {{ ticketDetail.productName }}
          </div>
          <div class="title2">
            {{ ticketDetail.priceTypeName }} / {{ num }} {{ Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets') }}
          </div>
          <div class="title2">
            {{ $t("app.non_refundable") }}
          </div>
          <div class="ticketDate">{{ ticketDetail.productUseDate }}</div>
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div>{{ $t("app.total") }}</div>
            <div style="font-weight: 600">
              ￥{{ priceFormat(ticketDetail.priceTypeList[0].totalAmount) }}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="bodyDiv">
      <div class="title">{{ $t("app.liaisonDetail") }}</div>
      <!-- <div class="infoDiv">
        <div class="infoType">{{ $t("app.name") }}</div>
        <div class="infoValue">{{ name }}</div>
      </div> -->
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.eMail") }}</div>
        <div class="infoValue">{{ eMail }}</div>
      </div>
    </div>

    <!-- <div class="payBtn" @click="toPay()">
      {{ $t("app.PayNow") }}
    </div> -->

    <div class="sctai" >
      <img :src="icon" alt="img">
      <div>
        <router-link :to="{path: '/SpecificCommercialTransactionsActInformation'}">{{ $t("app.SCTAI_title")}}</router-link>
      </div>
    </div>

    <div class="payBtn">
      <el-button round @click="toPay()">{{ $t('app.PayNow') }}</el-button>
    </div>

    <!-- <contact-us></contact-us> -->
  </div>
</template>

<script>
import apis from "../../utils/apis";
import ContactUs from "../../components/contactUs";
import { NavBar } from "vant";
import Vue from "vue";
import NoPicture from "../../assets/img/no_picture.png";
import icSactai from "../../assets/img/ic_sctai.png";
import axios from "axios";

Vue.use(NavBar);

let { login } = apis;
let { OrderDetail } = apis;

export default {
  components: { ContactUs },

  data() {
    return {
      icon:icSactai,
      NoPicture: NoPicture,
      title: this.$t("app.OrderDetails"),
      name: "UNKNOWN",
      eMail: "UNKNOWN",
      payUrl: "",
      ticketDetail: {},
      num: "",
    };
  },

  created() {
    // console.log(this.$route.query.ticketDetail, "----999----");
    this.payUrl = decodeURIComponent(this.$route.query.payUrl);
    this.ticketDetail = JSON.parse(this.$route.query.ticketDetail);
    this.num = this.ticketDetail.priceTypeList.length;
    this.name = this.$route.query.name;
    this.eMail = this.$route.query.email;
    // this.getOrderDetails();
  },

  methods: {
    toPay() {
      //跳转到支付页面
      location.replace(this.payUrl);
    },

    getOrderDetails() {
      let params = {
        mail: "", //注册用的邮箱，接收系统发送的验证码。
        orderId: "", //系统订单ID
      };
      OrderDetail({ ...params }, (data) => {
        // console.log("12345", data);
      });
    },

    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="less">
.sctai{
  a{
    color: black !important;
    text-decoration: underline;
  }
  img {
    width: 16px;
    height: 16px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.4rem;
  font-size: 16px;
  text-align: center;
  margin-left: -16px;
}

/deep/ .van-icon {
  color: black;
  font-size: 0.36rem;
}

/deep/ .van-nav-bar__title {
  font-weight: 700;
  font-size: 0.4rem;
}

.orderDetails {
  // background: #f8f8f8;
  font-size: 0.26rem;
  width: 1136px;
  margin: 110px auto 0;
  .trade_tips {
    background-color: #ECF3FC;
    margin: 0 30px;
    padding: 16px 30px;
    text-align: left;
    font-size: 14px;
    color: #eb455f;
  }

  .ticketDiv {
    border-bottom: 1px solid #000000;
    background: white;
    padding: 30px;

    .ticket1 {
      display: flex;
      text-align: left;
      width: 100%;
      align-items: center;

      .ticket_header {
        display: flex;
        flex-direction: column;
        height: 222px;
        width: 100%;
      }

      img {
        border-radius: 0.08rem;
        height: 222px;
        width: 290px;
        margin-right: 0.2rem;
      }

      .title1 {
        color: black;
        font-weight: 500;
        font-size: 0.3rem;
        flex: 1;
      }

      .title2 {
        color: #9a9a9a;
        font-size: 0.26rem;
        flex: 1;
      }

      .ticketDate {
        font-weight: 600;
        text-align: left;
        flex: 1;
      }
    }

  }

  .allIdsDiv {
    padding: 0.4rem;
    background: #ffffff;
    align-items: start;
    font-size: 0.26rem;
    margin-top: 0.34rem;
    text-align: left;

    .title {
      font-size: 0.36rem;
    }

    .infoDiv {
      margin-top: 0.4rem;
      height: 0.8rem;
    }
  }

  .infoValue {
    margin-top: 16px;
  }

   /deep/ .payBtn {
    background: #fff;
    display: flex;
    justify-content: center;
    padding: .4rem 0;
    .el-button {
      background: #f2b203;
      color: #000000;
      width: 321px;
      height: 54px;
      border-radius: 30px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .bodyDiv {
    padding: 30px;
    background: #ffffff;
    align-items: start;
    font-size: 0.2rem;
    text-align: left;
    color: #000000;
    border-bottom: 1px solid #000000;

    .title {
      font-size: 0.28rem;
    }

    .infoDiv {
      display: flex;
      padding: 0.1rem 0;

      .infoType {
        margin-top: 16px;
        width: 30%;
      }
    }
  }
}
</style>
